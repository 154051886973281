<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="11" stroke="#14142B" stroke-width="2"/>
		<path d="M9 11.9497L11.1213 14.071L15.364 9.82839" stroke="#14142B" stroke-width="2" stroke-linecap="round"/>
	</svg>
</template>

<script>
  export default {
    name: 'ActivateIcon'
  };
</script>