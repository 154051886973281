<template>
  <div class="tab-content" id="status-tab">
    <v-row>
      <v-col cols="12" sm="6">
        <label class="my-label">{{$t('voiceRecordings')}}</label>
        <v-textarea
          v-model="notes"
          filled
          color="#262338"
          background-color="#EFF0F7"
          auto-grow
          class="my-textarea pt-5"
        ></v-textarea>
      </v-col>

      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12">
            <label class="my-label">{{$t("generalStatus")}}</label>
            <v-select
              :items="filters.generalStatus"
              v-model="generalStatus"
              :label="$t('generalStatus')"
              :placeholder="$t('generalStatus')"
              solo
              flat
              hide-details
              color="#262338"
              background-color="#f5f7fa"
              class="my-select-box"
              append-icon="mdi-chevron-down"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template slot="item" slot-scope="data">
                <v-icon small :color="data.item.color">mdi-circle</v-icon>
                <span class="my-list-item">{{$t(data.item.text)}}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon small :color="data.item.color">mdi-circle</v-icon>
                <span class="my-list-item">{{$t(data.item.text)}}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <label class="my-label">{{$t("queenAge")}}</label>
            <v-select
              :items="filters.queenAge"
              v-model="queenAge"
              :label="$t('queenAge')"
              :placeholder="$t('queenAge')"
              solo
              flat
              hide-details
              color="#262338"
              background-color="#f5f7fa"
              class="my-select-box"
              append-icon="mdi-chevron-down"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template slot="item" slot-scope="data">
                <v-icon small :color="data.item.color">mdi-circle</v-icon>
                <span class="my-list-item">
                  {{$t(data.item.text)}}
                </span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon small :color="data.item.color">mdi-circle</v-icon>
                <span class="my-list-item">
                  {{$t(data.item.text)}}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="6" md="3" lg>
        <div class="label-padding">
          <label class="my-label">{{$t("broodFrames")}}</label>
        </div>
        <div class="d-inline-block mr-4">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="decreaseValue('broodFrames')"
            :disabled="broodFrames == 0 || broodFrames == null">
            <v-icon color="#262338">mdi-minus</v-icon>
          </v-btn>
        </div>
        <div class="d-inline-block mr-4">
          <p class="increment-step">{{broodFrames}}</p>
        </div>
        <div class="d-inline-block">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="increaseValue('broodFrames', 100)">
            <v-icon color="#262338">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" md="3" lg>
        <div class="label-padding">
          <label class="my-label">{{$t("populationFrames")}}</label>
        </div>
        <div class="d-inline-block mr-4">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="decreaseValue('populationFrames')"
            :disabled="populationFrames == 0 || populationFrames == null">
            <v-icon color="#262338">mdi-minus</v-icon>
          </v-btn>
        </div>
        <div class="d-inline-block mr-4">
          <p class="increment-step">{{populationFrames}}</p>
        </div>
        <div class="d-inline-block">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="increaseValue('populationFrames', 100)">
            <v-icon color="#262338">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" md="3" lg>
        <div class="label-padding">
          <label class="my-label">{{$t("harvestedFrames")}}</label>
        </div>
        <div class="d-inline-block mr-4">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="decreaseValue('harvestedFrames')"
            :disabled="harvestedFrames == 0 || harvestedFrames == null">
            <v-icon color="#262338">mdi-minus</v-icon>
          </v-btn>
        </div>
        <div class="d-inline-block mr-4">
          <p class="increment-step">{{harvestedFrames}}</p>
        </div>
        <div class="d-inline-block">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="increaseValue('harvestedFrames', 100)">
            <v-icon color="#262338">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" md="3" lg>
        <div class="label-padding">
          <label class="my-label">{{$t("honeycombs")}}</label>
        </div>
        <div class="d-inline-block mr-4">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="decreaseValue('honeycombs')"
            :disabled="honeycombs == 0 || honeycombs == null">
            <v-icon color="#262338">mdi-minus</v-icon>
          </v-btn>
        </div>
        <div class="d-inline-block mr-4">
          <p class="increment-step">{{honeycombs}}</p>
        </div>
        <div class="d-inline-block">
          <v-btn fab dark depressed color="#EFF0F7" width="32" height="32"
            class="plus-minus-btn" @click="increaseValue('honeycombs', 100)">
            <v-icon color="#262338">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import filters from '@/assets/filters.json';
  import moment from 'moment';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'StatusTab',
    computed: {
      ...mapGetters(["inspectionDates", "hiveDetails"]),
      generalStatus: {
        get () {
          return this.$store.state.hives.hiveDetails.generalStatus;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'generalStatus', value: value});
        }
      },
      queenAge: {
        get () {
          let result = '';
          let thisYear = moment().format("YYYY");
          let lastYear = moment().subtract(1, "years").format("YYYY");

          let queen = this.$store.state.hives.hiveDetails.q_bee_life_cycles;
          if (queen && ('birthyear' in queen)) {
            if (queen.birthyear == thisYear) {
              result = 'this';
            } else if (queen.birthyear == lastYear) {
              result = 'prev';
            } else {
              result = 'older';
            }
          }
          return result;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'queenAge', value: value});
        }
      },
      broodFrames: {
        get () {
          return this.$store.state.hives.hiveDetails.broodFrames;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'broodFrames', value: value});
        }
      },
      populationFrames: {
        get () {
          return this.$store.state.hives.hiveDetails.populationFrames;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'populationFrames', value: value});
        }
      },
      harvestedFrames: {
        get () {
          return this.$store.state.hives.hiveDetails.harvestedFrames;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'harvestedFrames', value: value});
        }
      },
      honeycombs: {
        get () {
          return this.$store.state.hives.hiveDetails.honeycombs;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'honeycombs', value: value});
        }
      },
      notes: {
        get () {
          return this.$store.state.hives.hiveDetails.notes;
        },
        set(value) {
          this.$store.commit('setHiveDetailsProp', {prop: 'notes', value: value});
        }
      }
    },
    data: () => ({
      filters: filters,
      checkbox_off: "$vuetify.icons.checkbox_off",
      checkbox_on: "$vuetify.icons.checkbox_on"
    }),
    methods: {
      ...mapActions(["addSelectedFilter", "setHiveDetailsProp"]),
      formatDate(date) {
        return moment(date, "YYYY/MM/DD").format("MMMM Do, yyyy");
      },
      decreaseValue(prop) {
        let oldValue = this.hiveDetails[prop];
        let newValue = this.hiveDetails[prop];
        if (oldValue != 0 && oldValue != null) {
          newValue = oldValue - 1;
        }
        this.setHiveDetailsProp({prop: prop, value: newValue});
      },
      increaseValue(prop, max) {
        let oldValue = this.hiveDetails[prop];
        let newValue = this.hiveDetails[prop];
        if (oldValue == null) {
          newValue = 0;
        } else if (oldValue < max) {
          newValue = oldValue + 1;
        }
        this.setHiveDetailsProp({prop: prop, value: newValue});
      }
    }
  }
</script>
