<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4 15L3.13176 14.5039C2.9549 14.8134 2.95617 15.1936 3.13509 15.5019C3.31401 15.8102 3.64353 16 4 16V15ZM12 1H13C13 0.546567 12.6949 0.149882 12.2567 0.0335004C11.8184 -0.0828815 11.3567 0.110171 11.1318 0.503861L12 1ZM12 15H13C13 14.4477 12.5523 14 12 14V15ZM12 23H11C11 23.4534 11.3051 23.8501 11.7433 23.9665C12.1816 24.0829 12.6433 23.8898 12.8682 23.4961L12 23ZM20 9L20.8682 9.49614C21.0451 9.18664 21.0438 8.80639 20.8649 8.49807C20.686 8.18976 20.3565 8 20 8V9ZM12 9H11C11 9.55229 11.4477 10 12 10V9ZM4.86824 15.4961L12.8682 1.49614L11.1318 0.503861L3.13176 14.5039L4.86824 15.4961ZM12 14H4V16H12V14ZM13 23V15H11V23H13ZM19.1318 8.50386L11.1318 22.5039L12.8682 23.4961L20.8682 9.49614L19.1318 8.50386ZM12 10H20V8H12V10ZM11 1V9H13V1H11Z" fill="#262338"/>
</svg>
</template>

<script>
  export default {
    name: 'LightningIcon'
  };
</script>