<template>
  <div>
    <v-chip v-if="keyword != ''"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetFilterAction('keyword')">mdi-close</v-icon>
      {{keyword}}
    </v-chip>
    <v-chip v-if="(starred != null) && (starred == true)"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetBoolFilterAction('starred')">mdi-close</v-icon>
      Starred
    </v-chip>
    <v-chip v-if="generalStatus != ''"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetFilterAction('generalStatus')">mdi-close</v-icon>
      General Status
    </v-chip>
    <v-chip v-if="queenAge != ''"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetFilterAction('queenAge')">mdi-close</v-icon>
      Queen Age
    </v-chip>
    <v-chip v-if="broodFrames[0] != 0 || broodFrames[1] != 25"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetSliderAction('broodFrames')">mdi-close</v-icon>
      Brood Frames
    </v-chip>
    <v-chip v-if="populationFrames[0] != 0 || populationFrames[1] != 50"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetSliderAction('populationFrames')">mdi-close</v-icon>
      Population Frames
    </v-chip>
    <v-chip v-if="harvestedFrames[0] != 0 || harvestedFrames[1] != 25"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetSliderAction('harvestedFrames')">mdi-close</v-icon>
      Harvested Frames
    </v-chip>
    <v-chip v-if="honeycombs[0] != 0 || honeycombs[1] != 50"
      class="ma-2 my-chip" color="#EFF0F6" text-color="#4E4B66">
      <v-icon left @click="resetSliderAction('honeycombs')">mdi-close</v-icon>
      Honeycombs
    </v-chip>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'FilterChips',
    computed: {
      ...mapGetters([
        "keyword",
        "starred",
        "generalStatus",
        "queenAge",
        "broodFrames",
        "populationFrames",
        "harvestedFrames",
        "honeycombs",
        "selectedFilters"
      ])
    },
    methods: {
      ...mapActions([
        "resetFilter",
        "resetBoolFilter",
        "resetSlider",
        "setHasFilters"
      ]),
      resetFilterAction(filter) {
        this.resetFilter(filter).then(() => {
          this.$gtag.event(
            'Reset Filter',
            {
              'event_category': 'Hive Filters',
              'event_label': filter
            }
          );

          let isEmptyFilters = Object.keys(this.selectedFilters).length === 0;
          if (isEmptyFilters) {
            this.setHasFilters(false);
          } else {
            this.$emit('update-filters');
          }
        });
      },
      resetBoolFilterAction(filter) {
        this.resetBoolFilter(filter).then(() => {
          this.$gtag.event(
            'Reset Filter',
            {
              'event_category': 'Hive Filters',
              'event_label': filter
            }
          );
          let isEmptyFilters = Object.keys(this.selectedFilters).length === 0;
          if (isEmptyFilters) {
            this.setHasFilters(false);
          } else {
            this.$emit('update-filters');
          }
        });
      },
      resetSliderAction(slider) {
        this.resetSlider(slider).then(() => {
          this.$gtag.event(
            'Reset Filter',
            {
              'event_category': 'Hive Filters',
              'event_label': slider
            }
          );
          let isEmptyFilters = Object.keys(this.selectedFilters).length === 0;
          if (isEmptyFilters) {
            this.setHasFilters(false);
          } else {
            this.$emit('update-filters');
          }
        });
      }
    }
  }
</script>

<style type="text/css">
  .my-chip {
    font-size: 13px !important;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.25px;
    height: 28px !important;
  }
</style>
