<template>
  <v-container fluid id="hives-page">
    <v-row class="pa-2 pa-sm-6 pa-md-6">
      <v-col cols="12" sm="12" md="6" lg="5" class="hives-left-col">
        <v-card flat class="my-card" id="hives-page-left-card">
          <h2>{{$t("hives")}}</h2>
          <p class="hive-paragraph">{{$t("hivesPage_text")}}</p>
          <v-row class="mt-sm-4">
            <v-col cols="12">
              <date-picker
                v-model="inspectionDate"
                :placeholder="$t('selectDate')"
                value-type="date"
                format="MMMM D, YYYY"
                :clearable="false"
                class="datepicker-container"
                popup-class="my-datepicker-popup"
                input-class="datepicker-btn"
                :lang="lang"
                :disabled-date="disabledDates"
                @change="getInspectionData"
              >
                <template v-slot:icon-calendar>
                  <v-img class="calendar-icon" src="@/assets/calendar.svg"></v-img>
                </template>
              </date-picker>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="status-col">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-img
                      contain
                      height="24"
                      width="24"
                      src="@/assets/bee_smoker.png"
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="my-title">
                        {{$t('inspected')}}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="my-title">
                      {{selectedInspectionDateObj.count}}
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-img
                      contain
                      height="24"
                      width="24"
                      src="@/assets/hives_total.png"
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div
                        class="my-title"
                        style="font-weight: 700;"
                      >
                        {{$t('total')}}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div
                      class="my-title"
                      style="font-weight: 700;"
                    >
                      {{apiaryHives.length}}
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row v-if="hasFilters">
            <FilterChips @update-filters="filterHives"/>
          </v-row>

          <v-row class="mt-5">
            <v-col cols="12" class="pb-0">
              <v-btn block outlined class="my-btn filter-btn"
                @click="filterHivesAction" :disabled="isApiaryHivesEmpty">
                <v-icon>{{filter_icon}}</v-icon>
                <v-spacer></v-spacer>
                {{$t("filter_hives")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-btn
                class="my-btn edit-hives-btn"
                depressed
                block
                :disabled="isApiaryHivesEmpty"
                @click="openActionsModal('task')"
              >
                <v-icon>mdi-plus</v-icon>
                <v-spacer></v-spacer>
                {{$t("newAction")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-menu offset-y min-width="280" content-class="add-menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    depressed
                    class="my-btn edit-hives-btn"
                    :disabled="isApiaryHivesEmpty"
                    v-on="on"
                  >
                    <v-icon>{{edit_icon}}</v-icon>
                    <v-spacer></v-spacer>
                    {{$t('editHives')}}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <EditHivesMenu
                  @open="openWarningModal"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="7" class="hives-right-col">
        <v-container class="pa-0 pa-md-3">
          <v-row>
            <v-col cols="12" class="d-flex justify-end align-center">
              <v-checkbox
                id="select-all"
                v-model="selectAll"
                :off-icon="checkbox_off"
                :on-icon="checkbox_on"
                class="my-checkbox"
                @change="selectAllHives"
              ></v-checkbox>
              <label
                for="select-all"
                class="select-all"
              >
                {{$t("select_all")}}
              </label>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col v-for="(item,index) in apiaryHives"
              :key="'hive-'+index" class="hive-box-container">
              <div class="hive-box" @click="singleClick(item)"
                @dblclick="doubleClick(item)"
                :style="defineHiveStyle(item)"
                >
                {{item.hiveNumber}}
              </div>
            </v-col>
            <v-col class="hive-box-container">
              <div class="hive-box add-hive-btn" @click="addHive">
                <v-img
                  contain
                  height="20"
                  width="16"
                  class="ma-auto"
                  src="@/assets/actions_plus.svg"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <FilterModal :visible="showFilterModal"
        @close="closeFilterModal" @save="filterHives"/>

      <HiveDetailsModal :visible="showHiveDetailsModal"
        @close="closeHiveDetailsModal"
        @update-inspection="updateHiveDetails"
        @new-inspection="saveInspection"
        @edit-hive-number="showEditHiveNumberModal = true"/>

      <ActionsModal :visible="showActionsModal"
        @close="closeActionsModal" @save="saveAction"/>

      <EditHiveNumberModal :visible="showEditHiveNumberModal"
        @close="showEditHiveNumberModal = false"
        @update-hive-number="updateHiveNumber"/>

      <ErrorModal :visible="showErrorModal"
        @close="showErrorModal = false"/>

      <SubscriptionModal :visible="showSubscriptionModal" 
        @close="showSubscriptionModal = false"
        @go-to-subscription-page="goToSubscriptionPage"/>

      <WarningModal
        :visible="showWarningModal"
        :actionType="actionType"
        @delete-hives="deleteHives"
        @activate-hives="activateHives"
        @deactivate-hives="deactivateHives"
        @close="closeWarningModal"
      />

      <v-overlay :value="showLoader" z-index=12>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import axios from "axios";

  import FilterModal from '@/components/HiveFilters/FilterModal.vue';
  import FilterChips from '@/components/HiveFilters/FilterChips.vue';
  import HiveDetailsModal from '@/components/HiveDetails/HiveDetailsModal.vue';
  import ActionsModal from '@/components/Actions/ActionsModal.vue';
  import EditHiveNumberModal from '@/components/HiveDetails/EditHiveNumberModal.vue';
  import AddMenu from '@/components/HivesPage/AddMenu.vue';
  import ErrorModal from '@/components/HiveDetails/ErrorModal.vue';
  import WarningModal from '@/components/EditHives/WarningModal.vue';
  import EditHivesMenu from "@/components/EditHives/EditHivesMenu.vue";
  import SubscriptionModal from "@/components/HivesPage/SubscriptionModal.vue";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';
  import moment from "moment";

  export default {
    name: 'Hives',
    components: {
      FilterModal,
      DatePicker,
      FilterChips,
      HiveDetailsModal,
      ActionsModal,
      EditHiveNumberModal,
      AddMenu,
      ErrorModal,
      WarningModal,
      EditHivesMenu,
      SubscriptionModal
    },
    computed: {
      ...mapGetters([
        "isSubscriptionMissing",
        "inventory",
        "apiaryHives",
        "selectedApiary",
        "inspectionDates",
        "hiveInspections",
        "hasFilters",
        "hiveDetails",
        "filteredHiveIds",
        "generalStatus",
        "queenAge",
        "broodFrames",
        "populationFrames",
        "harvestedFrames",
        "honeycombs",
        "keyword",
        "starred",
        "selectedHives",
        "newActionData",
        "newHiveTemplate",
        "selectedInspectionDateObj",
        "hiveNumber"
      ]),
      inspectionDate: {
        get() {
          let date = this.$store.state.inspections.selectedInspectionDate;
          return new Date(date);
        },
        set(date) {
          let formattedDate = moment(date).format("YYYY/MM/DD");
          this.$store.dispatch('setSelectedInspectionDate', formattedDate);
        }
      },
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      isApiaryHivesEmpty() {
        return this.apiaryHives.length === 0;
      }
    },
    data: () => ({
      showLoader: false,
      showFilterModal: false,
      showAddMenu: false,
      showHiveDetailsModal: false,
      showActionsModal: false,
      showEditHiveNumberModal: false,
      showErrorModal: false,
      showWarningModal: false,
      showConfirmDeleteModal: false,
      showSubscriptionModal: false,
      actionType: '',
      actionUrl: {
        'growth': 'growthAction',
        'feeding': 'feedingAction',
        'treatment': 'curingAction',
        'transport': 'transferAction',
        'task': 'taskAction'
      },
      edit_icon: "$vuetify.icons.edit_icon",
      filter_icon: "$vuetify.icons.filter_icon",
      checkbox_off: "$vuetify.icons.checkbox_off",
      checkbox_on: "$vuetify.icons.checkbox_on",
      selectAll: false,
      appHomeUrl: process.env.VUE_APP_HOME_URL
    }),
    mounted() {
      this.setIsHiddenApiaryButton(false);
      this.resetSelectedHives();
    },
    methods: {
      ...mapActions([
        "getInventory",
        "getApiaryHives",
        "getFilteredHives",
        "selectHive",
        "setApiaryButton",
        "getHiveInspections",
        "getHiveActions",
        "getHiveAnalysis",
        "setHiveDetails",
        "resetHiveDetails",
        "resetHiveInspections",
        "resetHiveActions",
        "resetHiveGraphs",
        "resetHiveDates",
        "setIsHiddenApiaryButton",
        "setActionsModalTabs",
        "setNewActionData",
        "resetSelectedHives",
        "getInspectionDates",
        "setLatestInspectionDate",
        "setSelectedInspectionDate",
        "updateSelectedApiary",
        "setHiveNumber",
        "setHiveBroodPopulationFrames",
        "setHiveNumberProp",
        "resetHiveDetailsTabs",
        "setHiveDetailsActiveTab",
        "toggleHiveActiveMult",
        "deleteHiveMult",
        "resetAllHives",
        "setHiveDetailsNewInspectionTab",
        "checkSubscription",
        "setActionDetails"
      ]),
      singleClick(hiveObj) {
        this.setHiveNumber({
          id: hiveObj.id,
          number: hiveObj.hiveNumber
        });

        this.selectHive(hiveObj.id);
      },
      doubleClick(hiveObj) {
        this.showLoader = true;

        this.setHiveNumber({
          id: hiveObj.id,
          number: hiveObj.hiveNumber
        });

        this.resetHiveDetails();
        this.resetHiveInspections();
        this.resetHiveActions();
        this.resetHiveGraphs();
        this.resetHiveDates();
        this.setActionDetails({});

        let promise1 = this.getHiveInspectionsMethod(hiveObj);
        let promise2 = this.getHiveActionsMethod(hiveObj);
        let promise3 = this.getHiveBroodFramesMethod(hiveObj);
        let promise4 = this.getHivePopulationFramesMethod(hiveObj);
        let promise5 = this.getHiveHarvestedFramesMethod(hiveObj);
        let promises = [promise1, promise2, promise3, promise4, promise5];

        Promise.all(promises).then((res) => {
          this.setHiveBroodPopulationFrames();
          this.showHiveDetailsModal = true;
          document.body.classList.add("modal-open");
          this.setApiaryButton(true);
          this.showLoader = false;
          this.clicks = 0;
        });
      },
      selectAllHives() {
        if (this.selectAll) {
          this.resetSelectedHives().then(() => {
            this.apiaryHives.forEach((el) => {
              this.selectHive(el.id);
            })
          });
        } else {
          this.resetSelectedHives();
        }
      },
      getHiveInspectionsMethod(hiveObj) {
        return new Promise((resolve, rejec) => {
          this.getHiveInspections(hiveObj.id).then(() => {
            let formattedDate = moment(this.inspectionDate).format("YYYY/MM/DD");

            if (this.hiveInspections.length != 0) {
              let matchedHiveInspection = this.hiveInspections.filter((item) => {
                return moment(item.dateCreated).format("YYYY/MM/DD") == formattedDate;
              });

              let hiveInspectionObj = this.hiveInspections[0]; //latest
              if (matchedHiveInspection.length != 0) {
                hiveInspectionObj = matchedHiveInspection[0];
              }

              this.setHiveDetails(hiveInspectionObj).then(() => {
                resolve();
              });
            } else {
              this.setHiveDetailsNewInspectionTab();
              this.setHiveDetails(this.newHiveTemplate).then(() => {
                resolve();
              });
            }
          });
        });
      },
      getHiveActionsMethod(hiveObj) {
        return new Promise((resolve, reject) => {
          let payload = {
            hiveId: hiveObj.id,
            completed: 0
          }
          this.getHiveActions(payload).then(() => {
            resolve();
          });
        });
      },
      getHiveBroodFramesMethod(hiveObj) {
        let fromDate = moment().subtract(365, "days").format("YYYY-MM-DD");
        let toDate = moment().format("YYYY-MM-DD");

        let payload = {
          hive_id: hiveObj.id,
          field: "broodFrames",
          date_from: fromDate,
          date_to: toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      getHivePopulationFramesMethod(hiveObj) {
        let fromDate = moment().subtract(365, "days").format("YYYY-MM-DD");
        let toDate = moment().format("YYYY-MM-DD");

        let payload = {
          hive_id: hiveObj.id,
          field: "populationFrames",
          date_from: fromDate,
          date_to: toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      getHiveHarvestedFramesMethod(hiveObj) {
        let fromDate = moment().subtract(365, "days").format("YYYY-MM-DD");
        let toDate = moment().format("YYYY-MM-DD");

        let payload = {
          hive_id: hiveObj.id,
          field: "harvestedFrames",
          date_from: fromDate,
          date_to: toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      disabledDates(date) {
        let tempDate = moment(date).format("YYYY/MM/DD");
        let matchedDates = false;
        for (let k = 0; k < this.inspectionDates.length; k++) {
          let inspectionDate = this.inspectionDates[k].date;
          if (tempDate == inspectionDate) {
            matchedDates = true;
            break;
          }
        }
        if (!matchedDates) {
          return new Date(tempDate);
        }
      },
      getInspectionData(date) {
        this.showLoader = true;
        this.getApiaryHives({
          'apiaryId': this.selectedApiary.id,
          'groupId': this.selectedInspectionDateObj.id,
        }).then(() => {
          this.filterHives();
        });
      },
      defineHiveStyle(hive) {
        let hiveId = hive.id;
        let selected = hive.selected;
        let inspected = hive.inspected;
        let active = this.apiaryHives.find(item => item.id == hiveId).active;

        //default
        let backgroundColor = '#d9dbe9';
        let textColor = '#262338';

        if (this.hasFilters) {
          let filteredHive = this.filteredHiveIds.find(item => item == hiveId);
          if (filteredHive != null) {
            backgroundColor = '#eaac30';
          }
        } else {
          if (inspected) {
            backgroundColor = '#A0A3BD';
          }

          if (!active) {
            backgroundColor = '#eff0f6';
          }
        }
        if (selected) {
          backgroundColor = '#262338';
          textColor = '#ffffff';
        }
        return {'background-color': backgroundColor, 'color': textColor};
      },
      filterHives() {
        if (this.hasFilters) {
          this.showLoader = true;

          var filters = {
            apiaryId: this.selectedApiary.id,
            inspectionGroup: this.selectedInspectionDateObj.id,
            generalStatus: this.stringToArray(this.generalStatus),
            queenAge: this.stringToArray(this.queenAge),
            broodFrames: this.getSliderFilterArray(this.broodFrames, 0, 25),
            harvestedFrames: this.getSliderFilterArray(this.harvestedFrames, 0, 25),
            populationFrames: this.getSliderFilterArray(this.populationFrames, 0, 50),
            honeycombs: this.getSliderFilterArray(this.honeycombs, 0, 50),
            notes: this.keyword,
            starred: this.starred ? 1 : 0
          };

          this.getFilteredHives(filters).then(() => {
            this.$gtag.event(
              'Filter hives',
              {
                'event_category': 'Hive Filters'
              }
            );
            this.showFilterModal = false;
            document.body.classList.remove("modal-open");
            this.setApiaryButton(false);
            this.showLoader = false;
          });
        } else {
          this.showFilterModal = false;
          document.body.classList.remove("modal-open");
          this.setApiaryButton(false);
          this.showLoader = false;
        }
      },
      addHive() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          this.showLoader = true;
          let hiveNumber = 1;

          if (this.apiaryHives.length != 0) {
            // apiaryHives is already sorted by hiveNumer ASC
            hiveNumber = this.apiaryHives[this.apiaryHives.length - 1].hiveNumber + 1;
          }

          let requestData = {
            apiaryId : this.selectedApiary.id,
            hiveNumber : hiveNumber,
            notes : ''
          }

          let url = `${process.env.VUE_APP_BASE_URL}/api/v1/hives/create`;
          axios.post(url, requestData).then(res => {
            this.$gtag.event(
              'Add new hive',
              {
                'event_category': 'Hives'
              }
            );

            let payload = {
              'apiaryId': this.selectedApiary.id,
              'groupId': this.selectedApiary.last_group_id
            };
            this.getApiaryHives(payload).then(() => {
              this.checkSubscription().then(() => {
                this.showLoader = false;
              });
            }).catch((er) => {
              console.log(er);
            });
          }).catch(er => {
            console.log(er);
          });
        }
      },
      stringToArray(filterString) {
        let result = [];
        if (filterString !== '') {
          result = [filterString];
        }
        return result;
      },
      getSliderFilterArray(filter, min, max) {
        let array = null;
        if (filter[0] != min && filter[1] != max) {
          array = [filter[0], filter[1]];
        } else if (filter[0] != min && filter[1] == max) {
          array = [filter[0], 100];
        } else if (filter[0] == min && filter[1] != max) {
          array = [filter[0], filter[1]];
        }
        return array;
      },
      updateHiveDetails() {
        console.log('updateHiveDetails');
        this.showLoader = true;
        let inspectionId = this.hiveDetails.id;
        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/inspections/${inspectionId}/update`, this.hiveDetails)
        .then(res => {
          this.$gtag.event(
            'Edit hive inspection',
            {
              'event_category': 'Inspections'
            }
          );

          let payload = {
            'apiaryId': this.selectedApiary.id,
            'groupId': this.selectedInspectionDateObj.id
          };
          this.getApiaryHives(payload).then(() => {
            this.showLoader = false;
            this.closeHiveDetailsModal();
          }).catch((er) => {
            console.log(er);
          });
        })
        .catch(er => {
          console.log(er);
        });
      },
      formatDate(date) {
        var dateArray = date.split('/');
        var result = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
        return new Date(result);
      },
      filterHivesAction() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          this.showFilterModal = true;
          document.body.classList.add("modal-open");
          this.setApiaryButton(true);
        }
      },
      closeFilterModal() {
        this.showFilterModal = false;
        document.body.classList.remove("modal-open");
        this.setApiaryButton(false);
      },
      closeHiveDetailsModal() {
        this.showHiveDetailsModal = false;
        document.body.classList.remove("modal-open");
        this.setHiveDetailsActiveTab(0);
        this.setApiaryButton(false);
        this.resetHiveDetailsTabs();
      },
      openActionsModal(type) {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          let hives_selected = this.selectedHives.map((item) => {
            return item.id;
          });
          this.actionType = type;

          this.setNewActionData({
            hives_selected: hives_selected,
            title: '',
            notes: '',
            initiated_at: '',
            completed: 0
          });

          let result = [
            {
              active: true,
              icon: '',
              name: 'action',
              component: 'task-tab'
            },
            {
              active: false,
              icon: 'box',
              name: 'selected_hives',
              component: 'hives-tab'
            }
          ];
          
          this.setActionsModalTabs(result).then(() => {
            this.setApiaryButton(true);
            this.showActionsModal = true;
            document.body.classList.add("modal-open");
            this.showAddMenu = false;
          });
        }
        
      },
      closeActionsModal() {
        this.showActionsModal = false;
        document.body.classList.remove("modal-open");
        this.setApiaryButton(false);
      },
      saveInspection(inspectionData) {
        console.log('saveInspection');
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/v1/hives/inspection_create_multi`;

        axios.post(url, {
          ...inspectionData
        }).then(res => {
          this.$gtag.event(
            'Save new inspection',
            {
              'event_category': 'Inspections'
            }
          );
          let selectedApiaryId = this.selectedApiary.id;
          this.resetSelectedHives();
          this.getInventory().then(() => {
            let selectedApiary = this.inventory.find(item => item.id == selectedApiaryId);
            this.updateSelectedApiary(selectedApiary).then(() => {
              this.getInspectionDates(this.selectedApiary.id).then(() => {
                if (this.inspectionDates.length != 0) {
                  let latestInspection = {
                    date: moment(this.selectedApiary.last_group_date).format("YYYY/MM/DD"),
                    id: this.selectedApiary.last_group_id
                  };
                  this.setLatestInspectionDate(latestInspection);
                  this.setSelectedInspectionDate(latestInspection.date);

                  let payload = {
                    'apiaryId': this.selectedApiary.id,
                    'groupId': this.selectedInspectionDateObj.id
                  };
                  this.getApiaryHives(payload).then(() => {
                    this.showLoader = false;
                    this.closeHiveDetailsModal();
                  }).catch((er) => {
                    console.log(er);
                  });
                }
              });
            });
          });
        }).catch(er => {
          console.log(er);
        });
      },
      saveAction() {
        this.showLoader = true;
        let actionData = JSON.parse(JSON.stringify(this.newActionData));
        actionData.initiated_at = moment(actionData.initiated_at).format("YYYY-MM-DD");
        let url = `${process.env.VUE_APP_BASE_URL}/api/generalAction`;

        axios.post(url, {
          ...actionData
        }).then(res => {
          this.$gtag.event(
            'Save new task action',
            {
              'event_category': 'Actions'
            }
          );
          this.closeActionsModal();
          if (actionData.destination_apiary && actionData.destination_apiary != null) {
            this.getApiaryHives({
              'apiaryId': this.selectedApiary.id,
              'groupId': this.selectedInspectionDateObj.id,
            }).then(() => {
              this.showLoader = false;
            });
          } else {
            this.showLoader = false;
          }
        }).catch(er => {
          console.log(er);
        });
      },
      updateHiveNumber() {
        this.showLoader = true;

        let payload = {
          "hiveId": this.hiveNumber.id,
          "hiveNumber": this.hiveNumber.number
        };

        axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/hives/update`,
          payload).then(res => {
          this.getApiaryHives({
            'apiaryId': this.selectedApiary.id,
            'groupId': this.selectedInspectionDateObj.id,
          }).then(() => {
            this.showLoader = false;
            this.showEditHiveNumberModal = false;
          });
        }).catch(er => {
          let oldHiveNumber = this.apiaryHives.find(item => item.id == this.hiveNumber.id).hiveNumber;
          this.setHiveNumberProp({prop: 'number', value: oldHiveNumber});
          this.showLoader = false;
          this.showEditHiveNumberModal = false;
          this.showErrorModal = true;
          console.log(er);
        });
      },
      openWarningModal(actionType) {
        this.actionType = actionType;
        this.showWarningModal = true;
        document.body.classList.add("modal-open");
      },
      closeWarningModal() {
        this.showWarningModal = false;
        document.body.classList.remove("modal-open");
      },
      deleteHives() {
        this.showLoader = true;
        this.closeWarningModal();
        let hives_selected = this.selectedHives.map((item) => {
          return item.id;
        });
        let payload = {
          hives_selected: hives_selected
        }
        this.deleteHiveMult(payload).then(() => {
          this.$gtag.event(
            'Delete hives',
            {
              'event_category': 'Hives'
            }
          );

          let payload_2 = {
            'apiaryId': this.selectedApiary.id,
            'groupId': this.selectedInspectionDateObj.id
          };
          this.getApiaryHives(payload_2).then(() => {
            this.checkSubscription().then(() => {
              this.showLoader = false;
            });
          });
        });
      },
      activateHives() {
        this.closeWarningModal();
        this.showLoader = true;
        let hives_selected = this.selectedHives.map((item) => {
          return item.id;
        });
        let payload = {
          hives_selected: hives_selected,
          active: 1
        }
        this.toggleHiveActiveMult(payload).then(() => {
          this.$gtag.event(
            'Activate hives',
            {
              'event_category': 'Hives'
            }
          );

          this.resetSelectedHives();
          this.showLoader = false;
        });
      },
      deactivateHives() {
        this.closeWarningModal();
        this.showLoader = true;
        let hives_selected = this.selectedHives.map((item) => {
          return item.id;
        });
        let payload = {
          hives_selected: hives_selected,
          active: 0
        }
        this.toggleHiveActiveMult(payload).then(() => {
          this.$gtag.event(
            'Deactivate hives',
            {
              'event_category': 'Hives'
            }
          );
          this.resetSelectedHives();
          this.showLoader = false;
        });
      },
      goToSubscriptionPage(plan) {
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/pay-session`;
        let requestData = {
          "success_url" : this.appHomeUrl,
          "cancel_url"  : this.appHomeUrl
        }
        if (plan == 'month') {
          url += '?plan=month';
        } else if (plan == 'year') {
          url += '?plan=year';
        }
        axios.post(url, requestData).then(res => {
          window.location.assign(res.data.checkout);
        }).catch(er => {
          console.log(er);
        });
      }
    }
  }
</script>

<style type="text/css">
  .hive-paragraph {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
    margin-top: 10px;
  }

  #hives-page .datepicker-btn {
    padding: 16px 24px;
  }

  #hives-page .datepicker-container .mx-icon-calendar {
    right: 18px;
  }

  #hives-page button.my-btn.add-btn {
    background-color: #EAAC30;
    border: 2px solid #EAAC30;
    font-size: calc(15px + (17 - 15) * ((100vw - 375px) / (1440 - 375)));
    letter-spacing: 0.75px;
  }

  #hives-page button.my-btn.add-btn:hover {
    background-color: #262338;
    border-color: #262338;
    color: #ffffff;
  }

  #hives-page button.add-btn:hover::before {
    opacity: 0;
  }

  .progress-container {
    height: 24px;
    text-align: right;
    align-items: center;
  }

  .progress-container .v-progress-linear--rounded,
  .progress-container .v-progress-linear__determinate {
    border-radius: 6px;
  }

  .progress-container .v-progress-linear__background {
    right: 0 !important;
    width: 100% !important;
  }

  #hives-page .row.status-row {
    margin-top: 0;
  }

  #hives-page .status-row-pa {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #hives-page .status-row .col {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #my-app .my-card button.filter-btn {
    font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
    line-height: 32px;
    letter-spacing: 0.75px;
    padding: 20px;
    height: 72px;
  }

  #my-app .my-card button.filter-btn:hover {
    background-color: #262338;
    color: #ffffff;
  }

  #my-app .my-card button.filter-btn:hover svg circle,
  #my-app .my-card button.filter-btn:hover svg path {
    stroke: #ffffff;
    fill: #ffffff;
  }

  button.filter-btn:hover::before {
    opacity: 0;
  }

  #my-app button.my-btn.edit-hives-btn {
    font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
    line-height: 32px;
    letter-spacing: 0.75px;
    background-color: #EAAC30;
    border: 2px solid #EAAC30;
    padding: 9px 22px;
    height: 72px;
  }

  #my-app button.my-btn.edit-hives-btn:hover {
    background-color: #ffffff;
    color: #262338;
  }

  #my-app button.edit-hives-btn:hover::before {
    opacity: 0;
  }

  .add-hive-btn {
    border: 2px solid #262338;
  }

  .my-chip {
    font-size: 13px !important;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.25px;
    height: 28px !important;
  }

  .no-data {
    font-size: calc(14px + (20 - 14) * ((100vw - 375px) / (1440 - 375)));
    font-weight: 600;
    color: #262338;
    letter-spacing: 0.75px;
    margin: 15px 0 0;
  }

  .select-all {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .select-all:hover {
    cursor: pointer;
  }

  .status-col {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  @media screen and (max-width: 1264px) {
    #hives-page .datepicker-btn,
    #hives-page .add-btn {
      padding: 16px;
    }

    #hives-page button.my-btn.add-btn {
      letter-spacing: 0;
    }

    #my-app #hives-page-left-card.my-card {
      padding: 35px 30px;
    }
  }

  @media screen and (max-width: 960px) {
    #my-app .my-card button.filter-btn {
      padding: 14px 20px;
      height: 50px;
    }

    #my-app .my-card button.edit-hives-btn {
      padding: 14px 20px;
      height: 50px;
    }
  }

  @media screen and (max-width: 600px) {
    #hives-page .datepicker-btn,
    #hives-page .add-btn {
      padding: 11px 16px;
    }

    .hives-left-col,
    .hives-right-col {
      padding-right: 12px;
    }

    .datepicker-container .mx-icon-calendar {
      right: 16px;
    }

    .calendar-icon {
      width: 20px;
    }
  }
</style>
