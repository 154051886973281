<template>
  <v-list id="add-menu-list">
    <v-list-item
      link
      class="mb-4"
      :disabled="isSelectedHivesEmpty"
      @click="$emit('open-inspections-modal')"
    >
      <v-list-item-icon>
        <v-img src="@/assets/view.svg"></v-img>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{$t("inspection")}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-group
      append-icon="mdi-chevron-right"
      class="my-4"
    >
      <template v-slot:activator>
        <v-list-item-icon>
        <LightningIcon />
      </v-list-item-icon>
        <v-list-item-title>{{$t("action")}}</v-list-item-title>
      </template>

      <v-list-item
        link
        :disabled="isSelectedHivesEmpty"
        @click="$emit('new-action', 'growth')"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{$t("growth")}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        link
        :disabled="isSelectedHivesEmpty"
        @click="$emit('new-action', 'feeding')"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{$t("feeding")}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        link
        :disabled="isSelectedHivesEmpty"
        @click="$emit('new-action', 'treatment')"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{$t("treatment")}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        link
        :disabled="isSelectedHivesEmpty"
        @click="$emit('new-action', 'transport')"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{$t("transport")}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        link
        :disabled="isSelectedHivesEmpty"
        @click="$emit('new-action', 'task')"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{$t("task")}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
  import {mapGetters} from 'vuex';
  import LightningIcon from '@/components/HivesPage/LightningIcon';

  export default {
    name: 'AddMenu',
    components: {
      LightningIcon
    },
    computed: {
      ...mapGetters(["selectedHives"]),
      isSelectedHivesEmpty() {
        return this.selectedHives.length === 0;
      }
    },
    data: () => ({

    }),

    methods: {
      
    }
  };
</script>

<style type="text/css">
  #add-menu-list {
    padding: 32px;
  }

  #add-menu-list .v-list-item__title {
    font-size: 15px;
    font-weight: 600;
    color: #262338;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  #add-menu-list .v-list-group__items .v-list-item {
    margin: 0 0 0 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #262338 !important;
  }

  #add-menu-list .v-icon {
    color: #262338;
  }

  #add-menu-list .v-list-item__icon,
  #add-menu-list .v-list-item__action {
    margin: 16px 16px 16px 0;
  }

  #add-menu-list .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(90deg);
    color: #EAAC30;
  }

  #add-menu-list .v-list-group--active > .v-list-group__header > .v-list-item__title {
    color: #EAAC30;
  }

  #add-menu-list .v-list-group--active > .v-list-group__header > .v-list-item__icon svg path {
    fill: #EAAC30;
  }
</style>