<template>
  <v-row class="mb-6">
    <v-col cols="12" md="6">
      <div class="graph-label">{{$t("broodPopulationFrames")}}</div>
      <NoData v-if="hasNoDataBroodPopulationFrames"></NoData>
      <div v-else>
        <GChart
          type="LineChart"
          :data="hiveBroodPopulationFrames"
          :options="broodPopChartOptions"
        />
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="graph-label">{{$t("harvestedFrames")}}</div>
      <NoData v-if="hasNoDataHarvestedFrames"></NoData>
      <div v-else>
        <GChart
          type="LineChart"
          :data="hiveHarvestedFrames"
          :options="chartOptions"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment';
  import {mapGetters} from 'vuex';
  import { GChart } from 'vue-google-charts'

  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'DiagramsTab',
    components: {
      GChart,
      NoData
    },
    computed: {
      ...mapGetters([
        "hiveBroodPopulationFrames",
        "hiveHarvestedFrames"
      ]),
      hasNoDataBroodPopulationFrames() {
        return this.hiveBroodPopulationFrames.length === 0;
      },
      hasNoDataHarvestedFrames() {
        return this.hiveHarvestedFrames.length === 0;
      },
    },
    data: () => ({
      broodPopChartOptions: {
        width: '100%',
        legend: {
          position: 'bottom',
          alignment:'center',
          textStyle: {
            color: '#4E4B66',
            fontSize: 12,
            fontName: 'Poppins'
          }
        },
        series: {
          0: {color: '#2aa8f8', pointShape: 'square', pointSize: 10},
          1: {color: '#03cfbb', pointShape: 'circle', pointSize: 5}
        },
        hAxis: {
          format: 'd/M/yy'
        },
        height: 300,
        chartArea: {
          width: '100%',
          height: '100%',
          top: 10,
          bottom: 50,
          left: 50,
          right: 50
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      },
      chartOptions: {
        width: '100%',
        legend: {
          position: 'bottom',
          alignment:'center',
          textStyle: {
            color: '#4E4B66',
            fontSize: 12,
            fontName: 'Poppins'
          }
        },
        series: {
          0: {color: '#2aa8f8', pointShape: 'circle', pointSize: 5}
        },
        hAxis: {
          format: 'd/M/yy'
        },
        height: 300,
        chartArea: {
          width: '100%',
          height: '100%',
          top: 10,
          bottom: 50,
          left: 50,
          right: 50
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      }
    })
  }
</script>

<style type="text/css">
  .graph-label {
    padding: 20px 0 0;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  #my-app .graph-tooltip {
    text-align: center;
    background: #262338;
    border-radius: 12px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
  }
</style>