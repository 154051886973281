<template>
  <v-row id="hive-details-actions" class="pa-sm-4 pa-md-6 pa-xl-8">
    <v-col cols="12" sm="12" md="6">
      <v-card flat class="my-card">
        <v-row>
          <v-col cols="6" sm="12" class="text-right">
            <v-menu offset-y min-width="193" content-class="add-menu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="#262338" v-on="on">
                  <v-icon>{{more_icon}}</v-icon>
                </v-btn>
              </template>
              <MoreMenu 
                :hidePrint="true"
                @view-completed="viewCompleted"
                @view-uncompleted="viewUncompleted"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
          id="actions-table"
          :headers="headers"
          :items="hiveActions"
          hide-default-header
          hide-default-footer
          :mobile-breakpoint="0"
          height="300px"
        >
          <template v-slot:item.checkbox="{ item }">
            <v-icon @click.stop="taskComplete(item)" v-if="!item.completed">
              {{checkbox_off}}
            </v-icon>

            <v-icon @click.stop="taskComplete(item)" v-else>
              {{completed_task_icon}}
            </v-icon>
          </template>
          <template v-slot:item.title="{ item }">
            <div @click.stop="taskDetails(item)"
              class="item-link"
              :class="{'active-task': item.active}">
              <div class="task-title">{{item.title}}</div>
              <div class="task-item-date d-flex" v-if="!item.active">
                <span class="tasks-calendar-icon">
                  <v-img
                    contain
                    height="18"
                    width="18"
                    :src="`${publicPath}img/icons/`+item.icon+'.svg'"
                  ></v-img>
                </span>
                <span>{{item.formattedDate}}</span>
                <span
                  v-if="(item.notes != '' && item.notes != null)"
                  class="file-icon"
                >
                  <v-img
                    contain
                    height="18"
                    width="18"
                    src="@/assets/file.svg"
                  ></v-img>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:item.delete="{ item }">
            <div @click.stop="hideAction(item)">
              <v-icon size="20">
                {{delete_icon}}
              </v-icon>
            </div>
          </template>
          <template v-slot:item.date="{ item }">
            <!-- Keep empty for not showing date on table -->
          </template>
        </v-data-table>

        <v-row class="mt-3">
          <v-col cols="8" offset="4" sm="6" offset-sm="6">
            <v-btn block outlined class="my-btn add-hive-btn"
              @click="$emit('add-action')">
              {{$t('newAction')}}
              <v-spacer></v-spacer>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="6" v-if="!isChosenTaskEmpty"
      class="hidden-sm-and-down">
      <ActionDetailsCard :showSmallEditBtn="true" :minHeight="300"
        @edit-action="$emit('edit-action')"/>
    </v-col>

    <ActionDetailsModal :visible="!isChosenTaskEmpty" class="hidden-md-and-up"
        @edit-action="$emit('edit-action')" @close="closeActionDetailsModal"/>

    <v-overlay :value="showLoader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
  import moment from 'moment';
  import axios from "axios";
  import {mapGetters, mapActions} from 'vuex';
  import MoreMenu from '@/components/HiveManagement/MoreMenu.vue';
  import ActionDetailsCard from '@/components/HiveManagement/ActionDetailsCard.vue';
  import ActionDetailsModal from '@/components/HiveManagement/ActionDetailsModal.vue'

  export default {
    name: 'ActionsTab',
    components: {
      MoreMenu,
      ActionDetailsCard,
      ActionDetailsModal
    },
    computed: {
      ...mapGetters([
        "hiveActions",
        "hiveDetails",
        "inventory",
        "selectedApiary",
        "actionDetails",
        "editActionItem",
        "completedTitle",
        "hiveNumber"
      ]),
      isChosenTaskEmpty() {
        return Object.keys(this.actionDetails).length == 0;
      }
    },
    data: () => ({
      showLoader: false,
      showEditActionModal: false,
      showActionDetailsModal: false,
      more_icon: "$vuetify.icons.more_icon",
      checkbox_off: "$vuetify.icons.checkbox_off",
      completed_task_icon: "$vuetify.icons.completed_task_icon",
      delete_icon: "$vuetify.icons.delete_icon",
      publicPath: process.env.BASE_URL,
      headers: [
        {text: '', align: 'start', sortable: false, value: 'checkbox'},
        {text: '', align: 'start', sortable: false, value: 'title'},
        {text: '', align: 'end', sortable: false, value: 'delete'},
        {text: '', align: 'd-none', sortable: false, value: 'date'}
      ],
      chosenTask: {}
    }),
    methods: {
      ...mapActions([
        "getHiveActions",
        "setActionDetails",
        "setHiveActionActive",
        "setEditActionItem",
        "hideGeneralAction"
      ]),
      viewCompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
          hiveId: this.hiveNumber.id,
          completed: 1
        };

        this.setActionDetails({});
        this.setEditActionItem({});

        this.getHiveActions(payload).then(() => {
          this.$gtag.event(
            'View completed actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      viewUncompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
          hiveId: this.hiveNumber.id,
          completed: 0
        };

        this.setEditActionItem({});
        this.setActionDetails({});
        this.getHiveActions(payload).then(() => {
          this.$gtag.event(
            'View uncompleted actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      taskDetails(item) {
        this.setHiveActionActive(item);
        this.setActionDetails(item);
      },
      taskComplete(item) {
        let actionCopy = JSON.parse(JSON.stringify(item));
        this.setEditActionItem(actionCopy).then(() => {
          this.updateAction(true);
        });
      },
      updateAction(toggleCompleted = false) {
        this.showLoader = true;

        let completed = toggleCompleted ? !this.editActionItem.completed : this.editActionItem.completed;

        let hives_selected = this.editActionItem.hive_numbers.map((el) => {
          return el.id;
        });

        let actionId = this.editActionItem.id;

        let actionData = {
          hives_selected: hives_selected,
          created_at: this.editActionItem.initiated_at,
          title: this.editActionItem.title,
          notes: this.editActionItem.notes,
          completed: completed ? 1 : 0
        };
       
        this.updateActionCall(actionId, actionData).then(() => {
          this.$gtag.event(
            'Edit action',
            {
              'event_category': 'Actions'
            }
          );
          if (this.completedTitle == 'viewCompleted') {
            this.viewUncompleted();
          } else {
            this.viewCompleted();
          }
        });
      },
      updateActionCall(actionId, actionData) {
        return new Promise((resolve, reject) => {
          axios.post(`${process.env.VUE_APP_BASE_URL}/api/generalAction/` + actionId, {
            ...actionData
          }).then(res => {
            resolve();
          }).catch(er => {
            console.log(er);
            reject();
          })
        })
      },
      hideAction(actionItem) {
        this.showLoader = true;
        this.hideGeneralAction(actionItem.id).then(() => {
          if (this.completedTitle == 'viewCompleted') {
            this.viewUncompleted();
          } else {
            this.viewCompleted();
          }
        });
      },
      closeActionDetailsModal() {
        this.setActionDetails({});
      }
    }
  }
</script>

<style type="text/css">
  #hive-details-actions .my-card {
    padding: 10px 20px 20px 30px;
  }

  #actions-table {
    margin-top: 8px;
  }

  #actions-table table td {
    padding: 15px 10px;
    border: none;
  }

  #actions-table table tr:hover {
    background: #EFF0F7;
  }

  #actions-table table td:first-child {
    width: 60px;
  }

  #actions-table table td:last-child {
    width: 0;
  }

  #actions-table table td:nth-child(3) {
    width: 30px;
    cursor: pointer;
  }

  #actions-table .v-data-footer {
    justify-content: flex-end;
    border: none;
    padding: 0;
  }

  .item-link {
    cursor: pointer;
  }

  .task-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  .task-item-date {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .file-icon {
    margin: auto 0 auto 16px;
  }

  .tasks-calendar-icon {
    margin: auto 10px auto 0;
  }

  .active-task {
    border-bottom: 3px solid #EAAC30;
  }

  div.active-task div.task-title {
    padding-bottom: 16px;
  }

  #hive-details-actions button.my-btn.add-hive-btn {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    padding: 15px 24px;
    background-color: #EAAC30;
    border: 2px solid #EAAC30;
    height: 54px;
  }

  #hive-details-actions button.my-btn.add-hive-btn:hover {
    background-color: #ffffff;
    color: #262338;
  }

  .hidden-element {
    visibility: hidden;
  }

  @media screen and (max-width: 960px) {
    #hive-details-actions .my-card {
      padding: 35px 25px;
    }
  }
</style>