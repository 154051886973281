<template>
  <div class="dialog-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container-sm">
      <v-card flat id="warning-card" class="my-card">
        <v-row>
          <v-col class="d-flex align-center">
            <div class="pr-2">
              <v-img
                contain
                height="24"
                width="24"
                class=""
                src="@/assets/alert_icon.svg"
              ></v-img>
            </div>
            <div>
              <div class="warning-title">{{$t("warning")}}</div>
            </div>
          </v-col>
        </v-row>

        <v-card-text>
          <div class="warning-content">
            {{$t('warning_message_1')}} <b>{{$t(actionType)}} {{selectedHives.length}} {{$t('hives')}}</b>. <span v-if="actionType == 'delete2'">{{$t('warning_message_2')}} </span>{{$t('warning_message_3')}}
          </div>
        </v-card-text>

        <v-card-actions class="my-card-actions mt-4">
          <v-row>
            <v-col cols="12" sm>
              <v-btn
                height="50px"
                outlined
                block
                class="my-btn red-btn red-btn-sm"
                @click="$emit('close')"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm>
              <v-btn
                height="50px"
                depressed
                block
                dark
                @click.stop="proceedAction"
                class="my-btn save-btn save-btn-sm"
              >
                {{$t("proceed")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'WarningModal',
    props: {
     visible: Boolean,
     actionType: String
    },
    computed: {
      ...mapGetters(["selectedHives"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    methods: {
      proceedAction() {
        let actionType = this.actionType.replace(/[0-9]/g, '');
        let event = actionType.toLowerCase() + '-hives';
        this.$emit(event);
      }
    }
  }
</script>

