<template>
  <div class="hive-details-modal-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container" id="hive-details-modal">
      <v-card flat id="hive-details-card" class="my-card">
        <v-row>
          <v-col class="d-flex" cols="8" sm="6" md="4" lg="3">
            <div class="hive-title-icon">
              <v-img
                contain
                height="24"
                width="24"
                class=""
                src="@/assets/box.svg"
              ></v-img>
            </div>
            <div>
              <div class="d-flex align-center">
                <h2>{{$t("hive")}} #{{hiveNumber.number}}</h2>
                <v-img
                  contain
                  height="20"
                  width="20"
                  color="#4E4B66"
                  src="@/assets/edit.svg"
                  class="hover-action ml-2"
                  @click="$emit('edit-hive-number')"
                ></v-img>
              </div>
              <p class="hive-subtitle">{{hiveDetails.apiaryName}}</p>
            </div>
            <div>
              <v-checkbox
                v-model="starredHive"
                color="#262338"
                hide-details
                :off-icon="starred_off_icon"
                :on-icon="starred_on_icon"
                class="my-checkbox pt-1 pl-5"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="4" sm="6" md="4" lg="6" class="text-right text-md-left">
            <div class="pt-1 switch-container">
              <label class="switch-label">{{$t("active")}}</label>
              <label class="switch">
                <input type="checkbox" v-model="activeHive">
                <span class="slider round"></span>
              </label>
            </div>
          </v-col>
          <v-col>
            <date-picker
              v-model="inspectionDate"
              :placeholder="$t('selectDate')"
              value-type="date"
              format="MMMM D, YYYY"
              :clearable="false"
              class="datepicker-container"
              :class="{'hide-element': !isCalendarVisible}"
              popup-class="my-datepicker-popup"
              input-class="datepicker-btn"
              :lang="lang"
              :disabled-date="(date) => date >= new Date()"
              :get-classes="highlightedDates"
              @change="getHiveDetails"
            >
              <template v-slot:icon-calendar>
                <v-img src="@/assets/calendar.svg"></v-img>
              </template>
            </date-picker>

            <v-select
              :items="dateDurations"
              v-model="chosenDateDuration"
              label="Select Date Range"
              placeholder="Select Date Range"
              solo
              flat
              hide-details
              color="#262338"
              class="graphs-select-box"
              :class="{'hide-element': !isGraphsSelectVisible}"
              append-icon="mdi-chevron-down"
              :menu-props="{ bottom: true, offsetY: true }"
              @change="updateHiveGraphs"
            >
              <template slot="item" slot-scope="data">
                <span class="my-list-item">{{$t(data.item.text)}}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <span class="my-list-item">{{$t(data.item.text)}}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="chosenDateDuration == 0 && isGraphsSelectVisible">
          <v-col md="7" lg="8" class="d-none d-md-block py-md-0"></v-col>
          <v-col cols class="py-md-0">
            <date-picker
              v-model="customDateDuration"
              :placeholder="$t('selectDate')"
              value-type="date"
              format="MMMM D, YYYY"
              :clearable="false"
              class="datepicker-container"
              popup-class="my-datepicker-popup graphs-datepicker-popup"
              input-class="datepicker-btn"
              range-separator=" - "
              :range="true"
              :lang="lang"
              :disabled-date="(date) => date >= new Date()"
              @change="updateHiveGraphs"
            >
              <template v-slot:icon-calendar>
                <v-img class="calendar-icon" src="@/assets/calendar.svg"></v-img>
              </template>
            </date-picker>
          </v-col>
        </v-row>

        <v-tabs
          grow
          hide-slider
          class="hive-details-tabs"
          background-color="#F7F7FC"
          color="#262338"
          v-model="activeTab"
        >
          <v-tab v-for="(tab, index) in hiveDetailsModalTabs"
            :key="'tab-' + tab.key"
            class="my-tab justify-center justify-md-start"
            :class="{'active-tab': tab.active}"
          >
            <v-img
              v-if="tab.active"
              contain
              height="24"
              width="24"
              class="tab-icon"
              :src="`${publicPath}img/icons/`+tab.icon+'_active.svg'"
            ></v-img>
            <v-img
              v-else
              contain
              height="24"
              width="24"
              class="tab-icon"
              :src="`${publicPath}img/icons/`+tab.icon+'_inactive.svg'"
            ></v-img>
            <span class="hidden-sm-and-down">{{$t(tab.name)}}</span>
          </v-tab>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="tab in hiveDetailsModalTabs"
              :key="'tab-content-' + tab.key"
            >
              <component v-bind:is="tab.component"
                @add-action="openActionsModal"
                @edit-action="openEditActionModal">
              </component>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-card-actions class="my-card-actions">
          <v-row>
            <v-col md="5" lg="6" class="d-none d-sm-none d-md-block"></v-col>
            <v-col cols="12" sm="6" md>
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn red-btn"
                @click="closeBtnAction"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md>
              <v-btn
                height="54px"
                depressed
                block
                dark
                @click.stop="saveHive"
                class="my-btn save-btn"
                :disabled="!isValidInspection"
              >
                {{$t("save")}}
                <v-spacer></v-spacer>
                <v-icon>{{check_icon}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <v-overlay :value="showLoader" z-index=11>
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>

    <NewActionModal :visible="showActionsModal"
      @close="closeActionsModal" @save="saveAction"/>

    <EditActionModal :visible="showEditActionModal"
      @close="closeEditActionModal" @save="updateAction"/>

    <SubscriptionModal :visible="showSubscriptionModal" 
      @close="showSubscriptionModal = false"
      @go-to-subscription-page="goToSubscriptionPage"/>
  </div>
</template>

<script>
  import StatusTab from '@/components/HiveDetails/StatusTab';
  import ActionsTab from '@/components/HiveDetails/ActionsTab';
  import DiagramsTab from '@/components/HiveDetails/DiagramsTab';
  import InspectionsStatusTab from '@/components/Inspections/InspectionsStatusTab';
  import NewActionModal from '@/components/Actions/NewActionModal.vue';
  import EditActionModal from '@/components/Actions/EditActionModal.vue';
  import SubscriptionModal from '@/components/HivesPage/SubscriptionModal.vue';

  import {mapGetters, mapActions} from 'vuex';
  import axios from 'axios';

  import moment from "moment";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'HiveDetailsModal',
    components: {
      'status-tab': StatusTab,
      'actions-tab': ActionsTab,
      'diagrams-tab': DiagramsTab,
      'inspections-status-tab': InspectionsStatusTab,
      DatePicker,
      NewActionModal,
      EditActionModal,
      SubscriptionModal
    },
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters([
        "isSubscriptionMissing",
        "hiveInspections",
        "hiveDetails",
        "hiveNumber",
        "apiaryHives",
        "hiveChosenDateDuration",
        "hiveCustomDateDuration",
        "hiveDetailsModalTabs",
        "newInspectionData",
        "newActionData",
        "actionDetails",
        "editActionItem",
        "selectedApiary",
        "completedTitle",
        "selectedInspectionDateObj"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isHiveDetailsEmpty() {
        return Object.keys(this.hiveDetails).length === 0;
      },
      inspectionDate: {
        get() {
          let result = '';
          if (!this.isHiveDetailsEmpty && this.hiveDetails.dateCreated != '') {
            result = new Date(this.hiveDetails.dateCreated);
          } else {
            if (this.$store.state.inspections.newInspectionDate) {
              result = new Date(this.$store.state.inspections.newInspectionDate);
            }
          }
          return result;
        },
        set(date) {
          let formattedDate = moment(date).format("YYYY/MM/DD");
          this.isNewInspection = this.checkInspection(formattedDate);

          this.hiveInspectionDate = formattedDate;

          if (this.isNewInspection) {
            this.$store.dispatch('setNewInspectionDate', formattedDate);
          }
        }
      },
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      activeHive: {
        get() {
          let active = false;
          if (this.apiaryHives.length !=0) {
            let matchedHive = this.apiaryHives.filter((item) => {
              return item.id == this.hiveNumber.id;
            });

            if (matchedHive.length != 0) {
              active = matchedHive[0].active;
            }
          }
          return active;
        },
        set(value) {
          let payload = {
            hiveId: this.hiveNumber.id,
            active: value
          }
          this.$store.dispatch('toggleHiveActive', payload).then(() => {
            let actionText = value ? 'Activate hive' : 'Deactivate hive';
            this.$gtag.event(
              actionText,
              {
                'event_category': 'Hives'
              }
            );
          });
        }
      },
      starredHive: {
        get() {
          let starred = 0;
          if (this.apiaryHives.length !=0) {
            let matchedHive = this.apiaryHives.filter((item) => {
              return item.id == this.hiveNumber.id;
            });

            if (matchedHive.length != 0) {
              starred = matchedHive[0].starred;
            }
          }
          return starred;
        },
        set(value) {
          let payload = {
            hiveId: this.hiveNumber.id,
            starred: value
          }
          this.$store.dispatch('toggleHiveStarred', payload).then(() => {
            let actionText = value ? 'Starred hive' : 'Non starred hive';
            this.$gtag.event(
              actionText,
              {
                'event_category': 'Hives'
              }
            );
          });
        }
      },
      chosenDateDuration: {
        get() {
          return this.$store.state.hives.hiveChosenDateDuration;
        },
        set(dateDuration) {
          this.$store.commit('setHiveChosenDateDuration', dateDuration);
        }
      },
      customDateDuration: {
        get() {
          return this.$store.state.hives.hiveCustomDateDuration;
        },
        set(dateDuration) {
          this.$store.commit('setHiveCustomDateDuration', dateDuration);
        }
      },
      activeTab: {
        get() {
          return this.$store.state.hives.hiveDetailsActiveTab;
        },
        set(index) {
          this.$store.dispatch('setHiveDetailsActiveTab', index);
        }
      },
      isValidInspection() {
        let isEmptyInspectionDate = this.inspectionDate == '';
        return !isEmptyInspectionDate;
      },
      isHiveCustomDateDurationEmpty() {
        return this.hiveCustomDateDuration == null || this.hiveCustomDateDuration == '';
      },
      isCalendarVisible() {
        let tabName = this.hiveDetailsModalTabs[this.activeTab].name;
        return tabName == 'status';
      },
      isGraphsSelectVisible() {
        let tabName = this.hiveDetailsModalTabs[this.activeTab].name;
        return tabName == 'graphs';
      }
    },
    data: () => ({
      showLoader: false,
      showActionsModal: false,
      showEditActionModal: false,
      showSubscriptionModal: false,
      hiveInspectionDate: moment().format("YYYY/MM/DD"),
      publicPath: process.env.BASE_URL,
      check_icon: "$vuetify.icons.check_icon",
      starred_on_icon: "$vuetify.icons.starred_on_icon",
      starred_off_icon: "$vuetify.icons.starred_off_icon",
      dateDurations: [
        {"value": 365, "text": "year"},
        {"value": 0, "text": "custom"},
      ],
      isNewInspection: false,
      appHomeUrl: process.env.VUE_APP_HOME_URL
    }),
    methods: {
      ...mapActions([
        "setHiveDetails",
        "toggleHiveActive",
        "toggleHiveStarred",
        "getHiveAnalysis",
        "setHiveDetailsActiveTab",
        "resetHiveGraphs",
        "rerenderGraphsTab",
        "setHiveBroodPopulationFrames",
        "resetHiveDetails",
        "setHiveDetailsNewInspectionTab",
        "resetHiveDetailsTabs",
        "setNewActionData",
        "setEditActionItem",
        "setActionDetails",
        "getUncompletedActions",
        "getCompletedActions",
        "resetNewInspectionData",
        "getApiaryHives",
        "getHiveActions"
      ]),
      updateTab(index) {
        this.setHiveDetailsActiveTab(index);
      },
      checkInspection(date) {
        let formattedDate = moment(new Date(date)).format("YYYY/MM/DD");
        let result = true;
        let hiveDetailsObj = this.hiveInspections.filter((item) => {
          return moment(item.dateCreated).format("YYYY/MM/DD") == formattedDate;
        });
        if (hiveDetailsObj.length != 0) {
          result = false;
        }
        return result;
      },
      highlightedDates(date) {
        let tempDate = moment(date).format("YYYY/MM/DD");
        let matchedDates = [];
        for (let k = 0; k < this.hiveInspections.length; k++) {
          let inspectionDate = moment(this.hiveInspections[k].dateCreated).format("YYYY/MM/DD");
          if (tempDate == inspectionDate) {
            matchedDates.push(inspectionDate);
          }
        }
        if (matchedDates.find(v => v === tempDate)) {
          return "highlight";
        }
        return "";
      },
      getHiveDetails() {
        this.resetHiveDetails().then(() => {
          let hiveDetailsObj = this.hiveInspections.filter((item) => {
            return moment(item.dateCreated).format("YYYY/MM/DD") == this.hiveInspectionDate;
          });
          if (hiveDetailsObj.length != 0) {
            this.resetHiveDetailsTabs().then(() => {
              this.setHiveDetails(hiveDetailsObj[0]);
            });
          } else {
            this.setHiveDetailsNewInspectionTab();
          }
        });
      },
      updateHiveGraphs() {
        let dateObj = {
          fromDate: '',
          toDate: ''
        };
        if (this.hiveChosenDateDuration != 0) {
          let fromDate = moment().subtract(this.hiveChosenDateDuration, "days").format("YYYY-MM-DD");
          let toDate = moment().format("YYYY-MM-DD");

          dateObj = {
            fromDate: fromDate,
            toDate: toDate
          };
          
        } else if (this.hiveChosenDateDuration == 0 && 
          !this.isHiveCustomDateDurationEmpty) {
          dateObj = {
            fromDate: moment(this.hiveCustomDateDuration[0]).format("YYYY-MM-DD"),
            toDate: moment(this.hiveCustomDateDuration[1]).format("YYYY-MM-DD")
          }
        }

        if (dateObj.fromDate != '' && dateObj.toDate != '') {
          this.showLoader = true;
          let promise1 = this.getHiveBroodFrames(dateObj);
          let promise2 = this.getHivePopulationFrames(dateObj);
          let promise3 = this.getHiveHarvestedFrames(dateObj);
          let promises = [promise1, promise2, promise3];
          this.resetHiveGraphs().then(() => {
            Promise.all(promises).then((res) => {
              this.setHiveBroodPopulationFrames();
              this.showLoader = false;
            });
          });
        }
      },
      getHiveBroodFrames(dateObj) {
        let payload = {
          hive_id: this.hiveDetails.hiveId,
          field: "broodFrames",
          date_from: dateObj.fromDate,
          date_to: dateObj.toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      getHivePopulationFrames(dateObj) {
        let payload = {
          hive_id: this.hiveDetails.hiveId,
          field: "populationFrames",
          date_from: dateObj.fromDate,
          date_to: dateObj.toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      getHiveHarvestedFrames(hiveObj) {
        let payload = {
          hive_id: this.hiveDetails.hiveId,
          field: "harvestedFrames",
          date_from: hiveObj.fromDate,
          date_to: hiveObj.toDate
        }
        return new Promise((resolve, reject) => {
          this.getHiveAnalysis(payload).then(() => {
            resolve();
          });
        });
      },
      openActionsModal() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          this.setNewActionData({
            hives_selected: [this.hiveNumber.id],
            title: '',
            notes: '',
            initiated_at: '',
            completed: 0
          });
          this.showActionsModal = true;
        }
      },
      closeActionsModal() {
        this.showActionsModal = false;
      },
      updateAction(toggleCompleted = false) {
        this.showLoader = true;
        let completed = toggleCompleted ? !this.editActionItem.completed : this.editActionItem.completed;

        let hives_selected = this.editActionItem.hive_numbers.map((el) => {
          return el.id;
        });

        let actionId = this.editActionItem.id;

        let actionData = {
          hives_selected: hives_selected,
          created_at: this.editActionItem.initiated_at,
          title: this.editActionItem.title,
          notes: this.editActionItem.notes,
          completed: completed ? 1 : 0
        };


        this.updateActionCall(actionId, actionData).then(() => {
          this.$gtag.event(
            'Edit action',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
          this.closeEditActionModal();
          if (this.completedTitle == 'viewCompleted') {
            this.viewUncompleted();
          } else {
            this.viewCompleted();
          }
        });
      },
      updateActionCall(actionId, actionData) {
        return new Promise((resolve, reject) => {
          axios.post(`${process.env.VUE_APP_BASE_URL}/api/generalAction/` + actionId, {
            ...actionData
          }).then(res => {
            resolve();
          }).catch(er => {
            console.log(er);
            reject();
          })
        })
      },
      viewCompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
          hiveId: this.hiveNumber.id,
          completed: 1
        };

        this.setActionDetails({});
        this.setEditActionItem({});

        this.getHiveActions(payload).then(() => {
          this.$gtag.event(
            'View completed actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      viewUncompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
          hiveId: this.hiveNumber.id,
          completed: 0
        };

        this.setEditActionItem({});
        this.setActionDetails({});
        this.getHiveActions(payload).then(() => {
          this.$gtag.event(
            'View uncompleted actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      saveAction() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          this.showLoader = true;
          let actionData = JSON.parse(JSON.stringify(this.newActionData));
          actionData.initiated_at = moment(actionData.initiated_at).format("YYYY-MM-DD");
          let url = `${process.env.VUE_APP_BASE_URL}/api/generalAction`;

          axios.post(url, {
            ...actionData
          }).then(res => {
            this.$gtag.event(
              'Save new action',
              {
                'event_category': 'Actions'
              }
            );
            this.closeActionsModal();
            if (actionData.destination_apiary && actionData.destination_apiary != null) {
              this.getApiaryHives({
                'apiaryId': this.selectedApiary.id,
                'groupId': this.selectedInspectionDateObj.id,
              }).then(() => {
                this.showLoader = false;
                this.$emit('close');
              });
            } else {
              this.viewUncompleted();
              this.showLoader = false;
            }
          }).catch(er => {
            console.log(er);
          });
        }
      },
      openEditActionModal() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          let actionCopy = JSON.parse(JSON.stringify(this.actionDetails));
          this.setEditActionItem(actionCopy);

          this.showEditActionModal = true;
          document.body.classList.add("modal-open");
        }
      },
      closeEditActionModal() {
        this.showEditActionModal = false;
        document.body.classList.remove("modal-open");
      },
      saveHive() {
        this.rerenderGraphsTab();
        let isNewInspection = this.checkInspection(this.inspectionDate);
        if (isNewInspection) {
          let hivesSelected = [this.hiveNumber.id];
          let inspection_data = {};

          for (const prop in this.newInspectionData) {
            let value = this.newInspectionData[prop];
            if (value !== '' && value !== null) {
              inspection_data[prop] = value;
            }
          }

          inspection_data['broodFrames'] = this.newInspectionData['broodFrames'];
          inspection_data['harvestedFrames'] = this.newInspectionData['harvestedFrames'];
          inspection_data['honeycombs'] = this.newInspectionData['honeycombs'];
          inspection_data['populationFrames'] = this.newInspectionData['populationFrames'];

          let inspectionData = {
            initiate_date: this.hiveInspectionDate,
            hives_selected: hivesSelected,
            inspection_data: inspection_data
          }
          this.$emit('new-inspection', inspectionData);
        } else {
          this.$emit('update-inspection');
        }
      },
      closeBtnAction() {
        this.rerenderGraphsTab();
        this.resetNewInspectionData();
        this.$emit('close');
      },
      goToSubscriptionPage(plan) {
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/pay-session`;
        let requestData = {
          "success_url" : this.appHomeUrl,
          "cancel_url"  : this.appHomeUrl
        }
        if (plan == 'month') {
          url += '?plan=month';
        } else if (plan == 'year') {
          url += '?plan=year';
        }
        axios.post(url, requestData).then(res => {
          window.location.assign(res.data.checkout);
        }).catch(er => {
          console.log(er);
        });
      }
    }
  }
</script>

<style type="text/css">
  .hive-details-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    overflow-y: scroll;
    z-index: 10;
  }

  .hive-title-icon {
    padding: 5px 5px 0 0;
  }

  .hive-subtitle {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  p.hive-subtitle {
    margin: 0;
  }

  #hive-details-card .my-card-actions {
    margin: 0;
  }

  #hive-details-card .my-checkbox {
    margin: 0;
    padding: 0;
  }

  .graphs-datepicker-popup {
    width: 652px;
  }

  .graphs-select-box {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    border-radius: 12px;
    background-color: #ffffff;
    border: 2px solid #EAAC30;
    padding: 16px 24px;
  }

  @media screen and (max-width: 960px) {
    #hive-details-card {
      min-height: 100vh;
    }
  }

  @media screen and (max-width: 600px) {
    #hive-details-card.my-card {
      padding: 20px 20px 70px;
    }
    
    #inspections-table table thead,
    #actions-table table thead {
      display: none;
    }

    #inspections-table table tbody tr td:first-child,
    #actions-table table tbody tr td:first-child {
      padding: 0 16px;
    }

    #inspections-table table tbody .v-data-table__mobile-row,
    #actions-table table tbody .v-data-table__mobile-row {
      justify-content: flex-start;
    }

    #inspections-table table tbody .v-data-table__mobile-row__cell,
    #actions-table table tbody .v-data-table__mobile-row__cell {
      text-align: left;
    }

    #hive-details-card .my-card-actions {
      margin-top: 20px;
    }

    .switch-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    #hive-details-modal .switch-label {
      padding-right: 0;
    }
  }
</style>
