<template>
  <v-list id="edit-hives-menu-list">
    <v-list-item
      link
      :disabled="isSelectedHivesEmpty"
      @click="$emit('open', 'delete2')"
      class="delete-hives"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{$t("delete")}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <DeleteIcon />
      </v-list-item-action>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item
      link
      :disabled="isSelectedHivesEmpty"
      @click="$emit('open', 'activate2')"
      class="activate-hives"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{$t("activate")}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <ActivateIcon />
      </v-list-item-action>
    </v-list-item>

    <v-list-item
      link
      :disabled="isSelectedHivesEmpty"
      @click="$emit('open', 'deactivate2')"
      class="deactivate-hives"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{$t("deactivate")}}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <DeactivateIcon />
      </v-list-item-action>
    </v-list-item>

  </v-list>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import DeleteIcon from '@/components/EditHives/DeleteIcon';
  import ActivateIcon from '@/components/EditHives/ActivateIcon';
  import DeactivateIcon from '@/components/EditHives/DeactivateIcon';

  export default {
    name: 'EditHivesMenu',
    components: {
      DeleteIcon,
      ActivateIcon,
      DeactivateIcon
    },
    computed: {
      ...mapGetters(["selectedHives"]),
      isSelectedHivesEmpty() {
        return this.selectedHives.length === 0;
      }
    },
    data: () => ({

    }),
    mounted() {
      this.setIsHiddenApiaryButton(false);
    },
    methods: {
      ...mapActions(["setIsHiddenApiaryButton"]),
    }
  };
</script>

<style type="text/css">
  #edit-hives-menu-list {
    padding: 32px;
  }

  #edit-hives-menu-list .v-list-item__title {
    font-size: 15px;
    font-weight: 600;
    color: #262338;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  #edit-hives-menu-list .v-list-group__items .v-list-item {
    margin: 0 0 0 40px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #262338 !important;
  }

  #edit-hives-menu-list .v-icon {
    color: #262338;
  }

  #edit-hives-menu-list .v-list-item__icon,
  #edit-hives-menu-list .v-list-item__action {
    margin: 16px 16px 16px 0;
  }

  #edit-hives-menu-list .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(90deg);
  }

  #edit-hives-menu-list .delete-hives:hover .v-list-item__title,
  #edit-hives-menu-list .deactivate-hives:hover .v-list-item__title {
    color: #FD6150;
  }

  #edit-hives-menu-list .delete-hives:hover .v-list-item__action svg path,
  #edit-hives-menu-list .deactivate-hives:hover .v-list-item__action svg path,
  #edit-hives-menu-list .deactivate-hives:hover .v-list-item__action svg circle {
    stroke: #FD6150;
  }

  #edit-hives-menu-list .activate-hives:hover .v-list-item__title {
    color: #03CFBB;
  }

  #edit-hives-menu-list .activate-hives:hover .v-list-item__action svg path,
  #edit-hives-menu-list .activate-hives:hover .v-list-item__action svg circle {
    stroke: #03CFBB;
  }
</style>