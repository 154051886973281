var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-sm-4 pa-md-6 pa-xl-8",attrs:{"id":"hive-details-actions"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"my-card",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","sm":"12"}},[_c('v-menu',{attrs:{"offset-y":"","min-width":"193","content-class":"add-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"#262338"}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.more_icon))])],1)]}}])},[_c('MoreMenu',{attrs:{"hidePrint":true},on:{"view-completed":_vm.viewCompleted,"view-uncompleted":_vm.viewUncompleted}})],1)],1)],1),_c('v-data-table',{attrs:{"id":"actions-table","headers":_vm.headers,"items":_vm.hiveActions,"hide-default-header":"","hide-default-footer":"","mobile-breakpoint":0,"height":"300px"},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [(!item.completed)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.taskComplete(item)}}},[_vm._v(" "+_vm._s(_vm.checkbox_off)+" ")]):_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.taskComplete(item)}}},[_vm._v(" "+_vm._s(_vm.completed_task_icon)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-link",class:{'active-task': item.active},on:{"click":function($event){$event.stopPropagation();return _vm.taskDetails(item)}}},[_c('div',{staticClass:"task-title"},[_vm._v(_vm._s(item.title))]),(!item.active)?_c('div',{staticClass:"task-item-date d-flex"},[_c('span',{staticClass:"tasks-calendar-icon"},[_c('v-img',{attrs:{"contain":"","height":"18","width":"18","src":_vm.publicPath + "img/icons/"+item.icon+'.svg'}})],1),_c('span',[_vm._v(_vm._s(item.formattedDate))]),((item.notes != '' && item.notes != null))?_c('span',{staticClass:"file-icon"},[_c('v-img',{attrs:{"contain":"","height":"18","width":"18","src":require("@/assets/file.svg")}})],1):_vm._e()]):_vm._e()])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.hideAction(item)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.delete_icon)+" ")])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return undefined}}])}),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"8","offset":"4","sm":"6","offset-sm":"6"}},[_c('v-btn',{staticClass:"my-btn add-hive-btn",attrs:{"block":"","outlined":""},on:{"click":function($event){return _vm.$emit('add-action')}}},[_vm._v(" "+_vm._s(_vm.$t('newAction'))+" "),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)],1),(!_vm.isChosenTaskEmpty)?_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ActionDetailsCard',{attrs:{"showSmallEditBtn":true,"minHeight":300},on:{"edit-action":function($event){return _vm.$emit('edit-action')}}})],1):_vm._e(),_c('ActionDetailsModal',{staticClass:"hidden-md-and-up",attrs:{"visible":!_vm.isChosenTaskEmpty},on:{"edit-action":function($event){return _vm.$emit('edit-action')},"close":_vm.closeActionDetailsModal}}),_c('v-overlay',{attrs:{"value":_vm.showLoader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }