<template>
  <div class="filter-modal-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container" id="filter-modal">
      <v-card flat id="filter-card" class="my-card">
        <div class="close-btn d-sm-none">
          <v-btn
            icon
            depressed
            color="#262338"
            width="24"
            height="24"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <h2>{{$t("filters")}}</h2>
        <div class="right-label d-none d-sm-block">
          <v-icon class="mr-2" color="#000000de">mdi-archive-outline</v-icon>
          {{$t("filtered_hives")}}
          <span class="pl-8">{{filteredHiveIds.length}}</span>
        </div>
        <v-row class="hidden-sm-and-up filtered-hives">
          <v-col cols="1">
            <v-icon class="mr-2" color="#000000de">mdi-archive-outline</v-icon>
          </v-col>
          <v-col>
            {{$t("filtered_hives")}}
          </v-col>
          <v-col cols="2">
            <span class="pl-8">{{filteredHiveIds.length}}</span>
          </v-col>
        </v-row>

        <div class="tab-content">
          <StatusTabContent />
        </div>

        <v-card-actions class="my-card-actions filter-actions">
          <v-row class="justify-end">
            <v-col sm md="3" class="d-none d-sm-block">
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn red-btn"
                @click="$emit('close')"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="3">
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn reset-btn"
                @click="resetAllFiltersAction"
              >
                {{$t("resetFilters")}}
                <v-spacer></v-spacer>
                <v-icon>{{refresh_icon}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm md="3">
              <v-btn
                height="54px"
                depressed
                block
                dark
                @click.stop="applyFilters"
                class="my-btn save-btn"
              >
                {{$t("filter")}}
                <v-spacer></v-spacer>
                <v-icon>{{check_icon}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import StatusTabContent from '@/components/HiveFilters/StatusTabContent';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'FilterModal',
    components: {
      StatusTabContent
    },
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters([
        "keyword",
        "starred",
        "generalStatus",
        "queenAge",
        "broodFrames",
        "populationFrames",
        "harvestedFrames",
        "honeycombs",
        "hasFilters",
        "filteredHiveIds"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data: () => ({
      refresh_icon: "$vuetify.icons.refresh_icon",
      check_icon: "$vuetify.icons.check_icon",
    }),
    methods: {
      ...mapActions([
        "setHasFilters",
        "resetAllFilters"
      ]),
      applyFilters() {
        let result = false;

        if (
          this.keyword != '' ||
          (this.starred != null && this.starred == true) ||
          this.generalStatus != '' ||
          this.queenAge != '' ||
          this.broodFrames[0] != 0 ||
          this.broodFrames[1] != 25 ||
          this.populationFrames[0] != 0 ||
          this.populationFrames[1] != 50 ||
          this.harvestedFrames[0] != 0 ||
          this.harvestedFrames[1] != 25 ||
          this.honeycombs[0] != 0 ||
          this.honeycombs[1] != 50
        ) {
          result = true;
          this.setHasFilters(result);
        } else {
          this.setHasFilters(result);
        }
        this.$emit('save');
      },
      resetAllFiltersAction() {
        this.resetAllFilters().then(() => {
          this.$gtag.event(
            'Reset All Filters',
            {
              'event_category': 'Hive Filters'
            }
          );
        });
      }
    }
  }
</script>

<style type="text/css">
  .filter-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    overflow-y: scroll;
    z-index: 10;
  }

  .filter-actions {
    margin-top: 20px;
  }

  #filter-card {
    position: relative;
    padding-top: 40px;
  }

  .right-label {
    position: absolute;
    background-color: #eaac30;
    padding: 10px 20px;
    height: 50px;
    top: 30px;
    right: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.75px;
  }

  .filtered-hives {
    margin: 12px -20px 0;
    background: #EAAC30;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  @media screen and (max-width: 600px) {
    .my-card .v-tabs.filter-tabs {
      margin: 0 -20px;
    }

    #filter-card.my-card {
      padding: 20px 20px 70px;
    }
  }

  @media screen and (max-width: 375px) {
    #filter-card.my-card .my-tab {
      justify-content: center;
    }
  }
</style>
