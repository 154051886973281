<template>
  <div class="my-modal-overlay" id="edit-hive-number-modal"
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container-center">
      <v-card flat id="edit-hive-number-card" class="my-card">
        <div class="close-btn">
          <v-btn
            icon
            depressed
            color="#262338"
            width="24"
            height="24"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col class="d-flex align-center">
            <h2>Edit Hive Number</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Hive number"
              v-model="hive_number"
              type="number"
              solo
              flat
              color="#262338"
              background-color="#f5f7fa"
              class="my-text-field"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-actions class="my-card-actions mt-5">
          <v-row>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn red-btn"
                @click="$emit('close')"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                depressed
                block
                dark
                class="my-btn save-btn"
                @click="updateHiveNumber"
                :disabled="isEmptyHiveNumber"
              >
                {{$t("save")}}
                <v-spacer></v-spacer>
                <v-icon>{{check_icon}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  export default {
    name: 'EditHiveNumberModal',
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters(["hiveNumber"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      hive_number: {
        get () {
          let result = 1;
          if (Object.keys(this.$store.state.hives.hiveNumber).length != 0) {
            result = this.$store.state.hives.hiveNumber.number;
          }
          return result;
        },
        set(value) {
          let result = parseInt(value);
          this.$store.commit('setHiveNumberProp', {prop: 'number', value: value});
        }
      },
      isEmptyHiveNumber() {
        return this.$store.state.hives.hiveNumber.number === '';
      }
    },
    data: () => ({
      check_icon: "$vuetify.icons.check_icon"
    }),
    methods: {
      updateHiveNumber() {
        this.$emit('update-hive-number');
      }
    }
  }
</script>

<style type="text/css">
  #edit-hive-number-modal .dialog-container-center {
    width: 40%;
    left: 30%;
  }

  #edit-hive-number-card.my-card {
    padding: 30px;
  }

  @media screen and (max-width: 960px) {
    #edit-hive-number-modal .dialog-container-center {
      width: 60%;
      left: 20%;
    }
  }

  @media screen and (max-width: 600px) {
    #edit-hive-number-modal .dialog-container-center {
      width: 80%;
      left: 10%;
    }
  }
</style>
