<template>
  <div>
    <v-row>
      <v-col cols sm="6" md>
        <label class="my-label">{{$t('searchVoiceRecordings')}}</label>
        <v-text-field
          v-model="keyword"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          :prepend-inner-icon="search_icon"
          class="my-text-field pt-5"
          @change="addKeywordFilter"
        ></v-text-field>
      </v-col>
      <v-col cols sm="6" md="2" class="starred-col">
        <label class="my-label" for="starred">
          {{$t('starred')}}
        </label>
        <v-checkbox
          v-model="starred"
          id="starred"
          color="#262338"
          hide-details
          :off-icon="starred_off_icon"
          :on-icon="starred_on_icon"
          class="my-checkbox pt-3"
          @change="addSelectedFilter('starred')"
        ></v-checkbox>
      </v-col>
      <v-col cols sm="6" md="3">
        <label class="my-label">{{$t("generalStatus")}}</label>
        <v-select
          :items="filters.generalStatus"
          v-model="generalStatus"
          @change="addSelectedFilter('generalStatus')"
          :label="$t('generalStatus')"
          :placeholder="$t('generalStatus')"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <v-icon small :color="data.item.color">mdi-circle</v-icon>
            <span class="my-list-item">{{$t(data.item.text)}}</span>
          </template>
          <template slot="selection" slot-scope="data">
            <v-icon small :color="data.item.color">mdi-circle</v-icon>
            <span class="my-list-item">{{$t(data.item.text)}}</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols sm="6" md="3">
        <label class="my-label">{{$t("queenAge")}}</label>
        <v-select
          :items="filters.queenAge"
          v-model="queenAge"
          @change="addSelectedFilter('queenAge')"
          :label="$t('queenAge')"
          :placeholder="$t('queenAge')"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#f5f7fa"
          class="my-select-box"
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="item" slot-scope="data">
            <v-icon small :color="data.item.color">mdi-circle</v-icon>
            <span class="my-list-item">
              {{$t(data.item.text)}}
            </span>
          </template>
          <template slot="selection" slot-scope="data">
            <v-icon small :color="data.item.color">mdi-circle</v-icon>
            <span class="my-list-item">
              {{$t(data.item.text)}}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row class="mt-md-8">
      <v-col cols="12" sm="6" md>
        <label class="my-label">{{$t("broodFrames")}}</label>
        <v-range-slider
          class="mt-5"
          v-model="broodFrames"
          @change="addSelectedFilter('broodFrames')"
          hide-details
          track-color="#d9dbe9"
          track-fill-color="#6e7191"
          thumb-color="#ffffff"
          :thumb-size="20"
          :thumb-label="true"
          max="25"
          min="0"
        ></v-range-slider>
      </v-col>
      <v-col cols="12" sm="6" md>
        <label class="my-label">{{$t("populationFrames")}}</label>
        <v-range-slider
          class="mt-5"
          v-model="populationFrames"
          @change="addSelectedFilter('populationFrames')"
          hide-details
          track-color="#d9dbe9"
          track-fill-color="#6e7191"
          thumb-color="#ffffff"
          :thumb-size="20"
          :thumb-label="true"
          max="50"
          min="0"
        ></v-range-slider>
      </v-col>
      <v-col cols="12" sm="6" md>
        <label class="my-label">{{$t("harvestedFrames")}}</label>
        <v-range-slider
          class="mt-5"
          v-model="harvestedFrames"
          @change="addSelectedFilter('harvestedFrames')"
          hide-details
          track-color="#d9dbe9"
          track-fill-color="#6e7191"
          thumb-color="#ffffff"
          :thumb-size="20"
          :thumb-label="true"
          max="25"
          min="0"
        ></v-range-slider>
      </v-col>
      <v-col cols="12" sm="6" md>
        <label class="my-label">{{$t("honeycombs")}}</label>
        <v-range-slider
          class="mt-5"
          v-model="honeycombs"
          @change="addSelectedFilter('honeycombs')"
          hide-details
          track-color="#d9dbe9"
          track-fill-color="#6e7191"
          thumb-color="#ffffff"
          :thumb-size="20"
          :thumb-label="true"
          max="50"
          min="0"
        ></v-range-slider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import filters from '@/assets/filters.json';
  import moment from 'moment';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'StatusTabContent',
    computed: {
      ...mapGetters(["inspectionDates"]),
      keyword: {
        get () {
          return this.$store.state.hive_filters.keyword;
        },
        set(value) {
          this.$store.commit('setKeyword', value);
        }
      },
      starred: {
        get () {
          return this.$store.state.hive_filters.starred;
        },
        set(value) {
          this.$store.commit('setStarred', value);
        }
      },
      generalStatus: {
        get () {
          return this.$store.state.hive_filters.generalStatus;
        },
        set(value) {
          this.$store.commit('setGeneralStatus', value);
        }
      },
      queenAge: {
        get () {
          return this.$store.state.hive_filters.queenAge;
        },
        set(value) {
          this.$store.commit('setQueenAge', value);
        }
      },
      honeycombs: {
        get () {
          return this.$store.state.hive_filters.honeycombs;
        },
        set(value) {
          this.$store.commit('setHoneycombs', value);
        }
      },
      populationFrames: {
        get () {
          return this.$store.state.hive_filters.populationFrames;
        },
        set(value) {
          this.$store.commit('setPopulationFrames', value);
        }
      },
      broodFrames: {
        get () {
          return this.$store.state.hive_filters.broodFrames;
        },
        set(value) {
          this.$store.commit('setBroodFrames', value);
        }
      },
      harvestedFrames: {
        get () {
          return this.$store.state.hive_filters.harvestedFrames;
        },
        set(value) {
          this.$store.commit('setHarvestedFrames', value);
        }
      }
    },
    data: () => ({
      filters: filters,
      checkbox_off: "$vuetify.icons.checkbox_off",
      checkbox_on: "$vuetify.icons.checkbox_on",
      search_icon: "$vuetify.icons.search_icon",
      starred_on_icon: "$vuetify.icons.starred_on_icon",
      starred_off_icon: "$vuetify.icons.starred_off_icon"
    }),
    methods: {
      ...mapActions(["addSelectedFilter"]),
      formatDate(date) {
        return moment(date, "YYYY/MM/DD").format("MMMM Do, yyyy");
      },
      addKeywordFilter() {
        if (this.keyword != '') {
          this.addSelectedFilter('keyword');
        }
      }
    }
  }
</script>

<style type="text/css">
  .starred-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>