<template>
  <div class="my-modal-overlay"
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container">
      <v-card flat id="new-action-card" class="my-card">
        <v-row>
          <v-col class="d-flex align-center" cols sm="2">
            <div class="inspections-title-icon">
              <v-img
                contain
                height="24"
                width="24"
                class=""
                src="@/assets/lightning.svg"
              ></v-img>
            </div>
            <div>
              <h2>{{$t("actions")}}</h2>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="5" offset-sm="4" offset-md="5">
            <date-picker
              v-model="actionDate"
              :placeholder="$t('selectDate')"
              value-type="date"
              format="MMMM D, YYYY"
              :clearable="false"
              class="datepicker-container"
              popup-class="my-datepicker-popup"
              input-class="datepicker-btn datepicker-btn-sm"
              :lang="lang"
            >
              <template v-slot:icon-calendar>
                <v-img src="@/assets/calendar.svg"></v-img>
              </template>
            </date-picker>
          </v-col>
        </v-row>

        <ActionsTaskTab />

        <v-card-actions class="my-card-actions inspections-actions">
          <v-row>
            <v-col md="5" lg="6" xl="7" class="d-none d-sm-none d-md-block"></v-col>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn red-btn"
                @click="cancelBtnAction"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                depressed
                block
                dark
                @click.stop="saveBtnAction"
                class="my-btn save-btn"
                :disabled="!isValidAction"
              >
                {{$t("save")}}
                <v-spacer></v-spacer>
                <v-icon>{{check_icon}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import ActionsTaskTab from '@/components/Actions/ActionsTaskTab';
  import {mapGetters, mapActions} from 'vuex';

  import moment from "moment";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'NewActionModal',
    components: {
      ActionsTaskTab,
      DatePicker
    },
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters([
        "selectedHives",
        "newActionData"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      actionDate: {
        get () {
          return this.$store.state.actions.newActionData.initiated_at;
        },
        set(value) {
          this.$store.commit('setNewActionProp', {prop: 'initiated_at', value: value});
        }
      },
      isValidAction() {
        let validationProps = ['initiated_at', 'title'];
        let isValid = true;

        if (this.newActionData.title === '' || this.newActionData.initiated_at === '' || this.newActionData.initiated_at === null) {
          isValid = false;
        }
        return isValid;
      }
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      activeTab: 0,
      check_icon: "$vuetify.icons.check_icon",
    }),
    methods: {
      ...mapActions(["setActiveTab", "setNewActionProp"]),
      updateTab(index) {
        this.activeTab = index;
        this.setActiveTab(index);
      },
      cancelBtnAction() {
        this.activeTab = 0;
        this.$emit('close');
      },
      saveBtnAction() {
        this.activeTab = 0;
        this.$emit('save');
      }
    }
  }
</script>

<style type="text/css">
  #new-action-card {
    position: relative;
  }

  @media screen and (max-width: 960px) {
    #new-action-card.my-card {
      padding: 35px 25px;
    }
  }

  @media screen and (max-width: 600px) {
    #new-action-card.my-card {
      padding: 35px 25px 70px;
    }
  }
</style>
